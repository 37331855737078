import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/list',
    component: () => import('@/views/list/index'),

  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/list/index'),
  },
]

const router = new VueRouter({
  routes
})
export default router
